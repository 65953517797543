<!-- App Main Structure -->
<template>
    <router-view></router-view>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Full',
  data: (vm) => ({
  }),
  computed: {},
  methods: {},
  mounted() {
  },
}
</script>
<style>
</style>
